// src/stores/userStore.ts
import { defineStore } from 'pinia'
import axios from 'axios'
import { type User } from '/@src/types'

interface SubscriptionStatus {
  isActive: boolean;
  expiresAt?: string;
  // Add any other relevant subscription information
}

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null as User | null,
    isLoading: false,
    subscriptionStatus: null as SubscriptionStatus | null,
    lastSubscriptionCheck: 0,
    subscriptionCheckPromise: null as Promise<boolean> | null,
  }),

  getters: {
    isAuthenticated: (state) => !!state.user,
    isSigned: (state) => !!state.user && state.user.signed,
    isSubscribed: (state) => !!state.subscriptionStatus?.isActive,
  },

  actions: {
    setUser(userData: User) {
      this.user = userData;
    },

    setSubscriptionStatus(status: SubscriptionStatus) {
      this.subscriptionStatus = status;
    },

    clearUser() {
      this.user = null;
      this.subscriptionStatus = null;
      this.lastSubscriptionCheck = 0;
      this.subscriptionCheckPromise = null;
    },

    async checkSubscription(token: string) {
      const now = Date.now();
      const oneHour = 60 * 60 * 1000;

      if (this.subscriptionCheckPromise) {
        return this.subscriptionCheckPromise;
      }

      if (now - this.lastSubscriptionCheck < oneHour && this.subscriptionStatus !== null) {
        return this.isSubscribed;
      }

      this.isLoading = true;
      this.subscriptionCheckPromise = new Promise(async (resolve) => {
        try {
          const response = await axios.get('/check-subscription', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          this.setSubscriptionStatus({
            isActive: response.data.is_active,
            expiresAt: response.data.expires_at,
          });
          this.lastSubscriptionCheck = now;
          resolve(this.isSubscribed);
        } catch (error) {
          console.error('Subscription check failed:', error);
          resolve(this.isSubscribed);
        } finally {
          this.isLoading = false;
          this.subscriptionCheckPromise = null;
        }
      });

      return this.subscriptionCheckPromise;
    },
  },
})