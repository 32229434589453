// src/composables/useAuth.ts
import { computed, ref, watch } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { useUserStore } from '/@src/stores/userStore';
import axios from 'axios';

export default function useAuth() {
  const auth0 = useAuth0();
  const userStore = useUserStore();
  const isAuth0Ready = ref(false);
  const isSubscriptionChecked = ref(false);
  const isInitialized = ref(false);
  const isAuthenticatedLocally = ref(false);
  
  if (!auth0 || !auth0.getAccessTokenSilently) {
    throw new Error('Auth0 is not properly initialized.');
  }

  const { getAccessTokenSilently } = auth0;

  const initialize = async () => {
    if (isInitialized.value) return;
    
    while (auth0.isLoading.value) {
      await new Promise(resolve => setTimeout(resolve, 50));
    }

    isInitialized.value = true;
  };

  const syncUserWithServer = async () => {
    try {
      const token = await getAccessTokenSilently();
      if (!token) return;
      
      const response = await axios.post('/auth/sync', {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      userStore.setUser(response.data.user);
      if (response.data.subscription) {
        userStore.setSubscriptionStatus(response.data.subscription);
      }
      isAuthenticatedLocally.value = true;
    } catch (error) {
      console.error('Error syncing user:', error);
      isAuthenticatedLocally.value = false;
    }
  };

  const checkSubscription = async () => {
    try {
      const token = await getAccessTokenSilently();
      if (!token) return false;
      
      const result = await userStore.checkSubscription(token);
      isSubscriptionChecked.value = true;
      return result;
    } catch (error) {
      console.error('Error checking subscription:', error);
      return false;
    }
  };

  watch(() => auth0.isLoading.value, async (loading) => {
    if (!loading) {
      await initialize();
    }
  }, { immediate: true });

  return {
    login: () => auth0.loginWithRedirect(),
    logout: () => auth0.logout({ logoutParams: { returnTo: window.location.origin } }),
    syncUserWithServer,
    isAuthenticated: computed(() => auth0.isAuthenticated.value),
    user: computed(() => auth0.user.value),
    isLoading: computed(() => auth0.isLoading.value || !isInitialized.value),
    isAuth0Ready,
    isSubscribed: computed(() => userStore.isSubscribed),
    isSubscriptionChecked,
    checkSubscription,
    getAccessTokenSilently: auth0.getAccessTokenSilently,
    initialize,
  };
}