import { createApp as createClientApp } from 'vue'
import { createHead } from '@unhead/vue'
import { InferSeoMetaPlugin } from '@unhead/addons'
import Toast, { type PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";
import navigationGuards from '/@src/plugins/navigation-guards';
import { createRouter } from '/@src/router'
import VulkApp from '/@src/VulkApp.vue'
import '/@src/styles'
import axios from 'axios';
import VueAxios from 'vue-axios';
import { createAuth0 } from '@auth0/auth0-vue';

const plugins = import.meta.glob<{ default: VulkPlugin }>('./plugins/*.ts')
export type VulkAppContext = Awaited<ReturnType<typeof createApp>>
export type VulkPlugin = (context: VulkAppContext) => void | Promise<void>

// this is a helper function to define plugins with autocompletion
export function definePlugin(plugin: VulkPlugin) {
  return plugin
}

axios.defaults.baseURL = import.meta.env.VITE_BACKEND_URL;

export async function createApp() {
  const app = createClientApp(VulkApp)
  const router = createRouter()
  const initialState = {} as Record<string, any>;
  const head = createHead({
    plugins: [InferSeoMetaPlugin()],
  });
  navigationGuards({ app, router, head, initialState });

  app.use(
    createAuth0({
      domain: import.meta.env.VITE_AUTH0_DOMAIN,
      clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    })
  );
  
  app.use(VueAxios, axios); 
  
  app.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 3,
    newestOnTop: true,
    filterBeforeCreate: (toast, toasts) => {
      if (toasts.filter(t => t.type === toast.type).length !== 0) {
        // Returning false discards the toast
        return false;
      }
      // You can modify the toast if you want
      return toast;
    }
  } as PluginOptions);

  app.use(head)

  const context = {
    app,
    router,
    head,
    initialState: {} as Record<string, any>,
  }

  context.initialState = (window as any).__vulk__ ?? {}

  app.provide('vulk', { plugins })
  
  for (const path in plugins) {
    if (!Object.hasOwn(plugins, path)) continue

    try {
      const { default: plugin } = await plugins[path]()
      await plugin(context)
    } catch (error) {
      console.error(`Error while loading plugin "${path}".`)
      console.error(error)
    }
  }

  app.use(router)
  return context
}