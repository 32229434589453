<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';

const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0();

const authCheckComplete = ref(false);

const showMenu = computed(() => authCheckComplete.value && isAuthenticated.value);
const showLoginButton = computed(() => authCheckComplete.value && !isAuthenticated.value);

const props = defineProps({
  assistantId: {
    type: [Number, String],
    default: null
  },
  slug: {
    type: String,
    default: ''
  },
  returnUrl: {
    type: String,
    default: ''
  },
  botpic: {
    type: String,
    default: ''
  }
});

onMounted(() => {
  watch(isLoading, (loading) => {
    if (!loading) {
      setTimeout(() => {
        authCheckComplete.value = true;
      }, 100);
    }
  }, { immediate: true });
});

const handleSignup = () => {
  loginWithRedirect({
    authorizationParams: {
      screen_hint: "signup",
    },
  });
};

const handleLogin = () => {
  loginWithRedirect();
};
</script>

<template>
  <nav
    role="navigation"
    aria-label="main navigation"
  >
    <Container class="navbar">
      <div class="navbar-brand">
        <div v-if="props.slug && !returnUrl">
          <RouterLink 
            :to="'/bot/' + props.slug"
            class="navbar-item"
          >
            <Button icon-only="feather:arrow-left">
              <AvatarSimple
                :picture="props.botpic || '/assets/illustrations/default_botpic.svg'"
                size="small"
                class="botpic"
              />
            </Button>
          </RouterLink>
        </div>
        <div
          v-else-if="returnUrl"
          class="navbar-item"
        >
          <a :href="returnUrl">
            <Button
              icon-only="feather:arrow-left"
            />
          </a>
        </div>
        <div v-else>
          <RouterLink 
          
            to="/" 
            class="navbar-item"
          >
            <DarkImage
              class="navbar-logo mr-2"
              src="/assets/logo/logo.svg"
              src-dark="/assets/logo/logo_white.svg"
              alt="8D-1 Logo"
            />
          </RouterLink>
        </div>
      </div>
      <div
        v-if="!authCheckComplete"
        key="loading"
        class="navbar-end mr-10"
      >
        <div class="loader" />
      </div>
      <Transition
        name="fade"
        mode="out-in"
      >
        <template v-if="authCheckComplete">
          <div
            v-if="showMenu"
            class="menu"
          >
            <div class="navbar-start hide">
              <Button to="/app">
                My Assistants
              </Button>
              <Button
                to="/app/create"
                icon-only="feather:plus"
                class="ml-2"
              />
            </div>
          </div>
          <div
            v-else-if="showLoginButton"
            class="navbar-end mr-4"
          >
            <ThemeToggle class="mr-4" />
            <Button
              color="primary"
              class="ml-4 hide"
              :long="3"
              outlined
              @click="handleLogin"
            >
              Log in
            </Button>
            <Button
              color="primary"
              class="ml-4"
              :long="3"
              @click="handleSignup"
            >
              Sign up
            </Button>
          </div>
        </template>
      </Transition>
      
      <BurgerMenu
        v-if="showMenu"
        class="burger-menu"
        @logout="logout"
      />
    </Container>
  </nav>
</template>

<style scoped>
.navbar {
  padding-top: 1rem;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.navbar-start, .navbar-end {
  display: flex;
  align-items: center;
}

.burger-menu {
  margin-left: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media only screen and (width <= 767px) {
  .hide {
    display: none;
  }
}

.botpic {
  margin: 0 1rem;
  border: 1px solid var(--card-border-color);
  border-radius: 50%;
}
</style>