<script setup lang="ts">
import { defineProps } from 'vue';

interface HeroTestimonialProps {
  avatarImage: string;
  name: string;
  title: string;
  quote: string;
  centered?: boolean;
}

const props = withDefaults(defineProps<HeroTestimonialProps>(), {
  centered: false
});
</script>

<template>
  <div
    class="hero-testimonial"
    :class="{ 'is-centered': props.centered }"
  >
    <p class="paragraph mb-2 max-w-4">
      "{{ props.quote }}"
    </p>
    <div class="testimonial-author">
      <AvatarSimple
        size="medium"
        squared
        :picture="props.avatarImage"
      />
      <div class="meta">
        <Title
          tag="h3"
          :size="7"
          weight="semi"
          narrow
        >
          {{ props.name }}
        </Title>
        <p class="paragraph rem-85">
          {{ props.title }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hero-testimonial {
  text-align: left;

  &.is-centered {
    text-align: center;

    .testimonial-author {
      flex-direction: column;
      align-items: center;

      .meta {
        margin-left: 0;
        margin-top: 0.75rem;
      }
    }
  }

  .testimonial-author {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .meta {
      margin-left: 0.75rem;
    }
  }
}
</style>