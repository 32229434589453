<script setup>
import { ref } from 'vue';
import { useManageSubscription } from '/@src/composables/useManageSubscription';
import useAuth from '/@src/composables/useAuth';

const { isAuthenticated, login } = useAuth();
const { subscriptionLink, manageSubscription } = useManageSubscription();

const isLoading = ref(false);
const error = ref(null);

const handleSubscription = async () => {
  isLoading.value = true;
  error.value = null;

  try {
    if (isAuthenticated.value) {
      // User is authenticated, handle subscription
      await manageSubscription();
      if (subscriptionLink.value) {
        window.location.href = subscriptionLink.value;
      } else {
        throw new Error("No subscription link received");
      }
    } else {
      // User is not authenticated, handle login
      await login();
      // Note: After successful login, the user will be redirected back to this page
      // You may want to handle post-login subscription management in a mounted hook or a separate function
    }
  } catch (err) {
    console.error("Failed to process subscription:", err);
    error.value = err.message || "An error occurred while processing your request.";
  } finally {
    isLoading.value = false;
  }
};
</script>

<template>
  <PricingSoloCentered
    :features="['No monthly fees', '100 free messages', 'Then $9 / 100 messages', 'No credit card required']"
    :dollars="0"
    cents="09"
    :loading="isLoading"
    link-label="Start for free"
    @click="handleSubscription"
  />
  <p
    v-if="error"
    class="error-message"
  >
    {{ error }}
  </p>
</template>

<style scoped>
.error-message {
  color: red;
  margin-top: 10px;
}
</style>