<script setup lang="ts">
import type { ImageFeature } from '/@src/types'

export interface FeatureBlockIProps {
  columns?: number
  limit?: number
  links?: boolean
  rounded?: boolean
  inverted?: boolean
}

const props = withDefaults(defineProps<FeatureBlockIProps>(), {
  columns: 4,
  limit: 3,
  rounded: false,
  links: false,
  inverted: false,
})

const imageClasses = computed(() => [props.rounded && `image-rounded`])

const columnClasses = computed(() => [props.columns && `is-${props.columns}`])

const subtitleClasses = computed(() => [props.inverted && 'text-light'])

const howitworks = [
  {
    "title": "Create an assistant easily and share a link to your bot with your target audience.",
    "image": "/assets/illustrations/task.svg",
    "darkImage": "/assets/illustrations/task_dark.svg"
  },
  {
    "title": "Your assistant chats with your audience, gathering the information you need.",
    "image": "/assets/illustrations/theychat.svg",
    "darkImage": "/assets/illustrations/theychat_dark.svg"
  },
  {
    "title": "Check back to review chat transcripts and get insights into your audience.",
    "image": "/assets/illustrations/result.svg",
    "darkImage": "/assets/illustrations/result_dark.svg"
  }
]

// Convert howitworks to the expected format for features
const features: ImageFeature[] = howitworks.map(item => ({
  title: item.title,
  image: item.image,
  darkImage: item.darkImage,
}))

</script>

<template>
  <div class="py-6">
    <div class="pb-6">
      <Title
        tag="h2"
        :size="3"
        weight="bold"
        leading
        :inverted="inverted"
      >
        Simple as a toaster
      </Title>
      <p
        class="paragraph max-w-7 pt-2"
        :class="subtitleClasses"
      >
        Instantly create and share personalized AI agents for any task.
      </p>
    </div>

    <div class="columns is-multiline b-columns-half-tablet-p">
      <div
        v-for="(feature, index) in features"
        :key="index"
        class="column mobile:mb-6"
        :class="columnClasses"
      >
        <DarkImage
          :class="[...imageClasses]"
          :src="feature.image"
          :src-dark="feature.darkImage"
          alt=""
        />
        <div>
          <p
            class="paragraph max-w-7 pt-2 rem-5"
          >
            <span>{{ ` ${feature.title}` }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.image-rounded {
  border-radius: 1.5rem;
}

:deep(.action-link) {
  margin-top: 0.25rem;
}
</style>
