import { definePlugin } from '/@src/app';
import useAuth from '/@src/composables/useAuth'

export default definePlugin(({ router }) => {
  router.beforeEach(async (to, from, next) => {
    const { isAuthenticated, isLoading, login, initialize } = useAuth();

    await initialize();

    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!isAuthenticated.value) {
        sessionStorage.setItem('redirectPath', to.fullPath);
        login();
        return;
      }
    }

    if (!isLoading.value) {
      next();
    }
  });

  router.beforeResolve(async (to, from, next) => {
    const { isAuthenticated, initialize } = useAuth();
    
    await initialize();

    if (isAuthenticated.value) {
      const redirectPath = sessionStorage.getItem('redirectPath');
      if (redirectPath) {
        sessionStorage.removeItem('redirectPath');
        next(redirectPath);
        return;
      }
    }
    
    next();
  });
});