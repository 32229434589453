//current version

import {
  START_LOCATION,
  createRouter as createClientRouter,
  createWebHistory,
  type RouteRecordRaw,
  type RouteRecordRedirect,
  type RouteRecordSingleView,
  type RouteRecordMultipleViews,
} from 'vue-router'
import useAuth from '/@src/composables/useAuth'
import routes from 'pages-generated'

type AnyRouteRecord = RouteRecordRaw | RouteRecordRedirect | RouteRecordSingleView | RouteRecordMultipleViews;

const additionalRoutes: AnyRouteRecord[] = [
  {
    path: '/widget',
    name: 'Widget',
    component: () => import('/@src/pages/widget/index.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('/@src/pages/[...all].vue'),
    meta: { requiresAuth: false }
  }
];

export function createRouter() {
  const allRoutes = markRoutesAsAuthRequired([...additionalRoutes, ...routes]);

  const router = createClientRouter({
    history: createWebHistory(),
    routes: allRoutes,
    scrollBehavior: async (to, from, savedPosition) => {
      // scroll instantly to hash on first view
      if (to.hash && from === START_LOCATION) {
        return {
          el: to.hash,
        }
      }

      // smooth scroll to hash on page change
      if (to.hash) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              el: to.hash,
              behavior: 'smooth',
            })
          }, 500)
        })
      }

      // return on saved position if any
      if (savedPosition) return savedPosition

      // scroll to top on page change
      return { top: 0, behavior: 'smooth' }
    },
  })

  router.beforeEach(async (to, from, next) => {
    const { isAuthenticated, isLoading, login, initialize } = useAuth();

    // Initialize authentication
    await initialize();

    // If the route requires authentication
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // Wait for the authentication process to complete
      if (isLoading.value) {
        // You might want to show a loading indicator here
        await new Promise(resolve => setTimeout(resolve, 100));
        return next();
      }

      // If not authenticated, redirect to login
      if (!isAuthenticated.value) {
        sessionStorage.setItem('redirectPath', to.fullPath);
        login();
        return next(false);
      }
    }

    // Proceed to the route
    next();
  });

  router.beforeResolve(async (to, from, next) => {
    const { isAuthenticated } = useAuth();
    
    if (isAuthenticated.value) {
      const redirectPath = sessionStorage.getItem('redirectPath');
      if (redirectPath) {
        sessionStorage.removeItem('redirectPath');
        return next(redirectPath);
      }
    }
    
    next();
  });

  return router;
}

function markRoutesAsAuthRequired(routes: AnyRouteRecord[]): AnyRouteRecord[] {
  return routes.map(route => {
    if ('path' in route && route.path.startsWith('/app')) {
      return {
        ...route,
        meta: { ...route.meta, requiresAuth: true },
        children: 'children' in route && route.children 
          ? markRoutesAsAuthRequired(route.children)
          : undefined,
      } as AnyRouteRecord;
    }
    return route;
  });
}