<route lang="yaml">
  meta:
    navbar:
      enabled: true
    footer:
      enabled: true
    title: "8D-1 Assistants"
    type: "website"
    url: "https://8d-1.com"
    image: "https://8d-1.com/assets/illustrations/8dmain.png"
    description: "Make AI your initial point of contact. Instantly create and share personal AI agents for any case."
    og:title: "8D-1 Assistants"
    og:type: "website"
    og:url: "https://8d-1.com"
    og:image: "https://8d-1.com/assets/illustrations/8dmain.png"
    og:description: "Make AI your initial point of contact. Instantly create and share personal AI agents for any case."
  </route>
<script setup lang="ts">
import { type IconBoxColor } from '/@src/components/base/icon/IconBox.vue'

const values = [
  {
    id: 0,
    image: '/assets/illustrations/product.png',
    darkImage: '/assets/illustrations/product.png',
    title: 'Product Management',
    content:
      'Take customer discovery on autopilot. Our AI chatbot can conduct interviews at scale, providing you with the data you need to make informed decisions.',
  },
  {
    id: 1,
    image: '/assets/illustrations/busdev.png',
    darkImage: '/assets/illustrations/busdev.png',
    title: 'Business Development',
    content:
      '8D-1 will not just chat with incoming leads; it will advocate for your brand, perform initial screenings, and provide you with comprehensive summaries.',
  },
  {
    id: 3,
    image: '/assets/illustrations/hr.png',
    darkImage: '/assets/illustrations/hr.png',
    title: 'Human Resources',
    content:
      'Never miss the next big talent. Let AI chat with potential candidates, screen them, and provide you with structured and professional reviews.',
  },
]

const featuresAll = [
  {
    title: 'Telegram Native',
    text: 'Just paste your Telegram bot API token to connect 8D-1 to Telegram.',
    color: 'info' as IconBoxColor,
    icon: 'feather:send',
  },
  {
    title: 'Unlimited Bots',
    text: 'Create as many bots as you need to cover all your business needs.',
    color: 'yellow' as IconBoxColor,
    icon: 'feather:grid',
  },
  {
    title: 'General Expertise',
    text: 'Powered by OpenAI, 8D-1 possesses general expertise in many fields.',
    color: 'red' as IconBoxColor,
    icon: 'feather:book-open',
  },
  {
    title: 'Chat Follow-ups',
    text: 'Join the chat when needed or reactivate it with a follow-up question.',
    color: 'primary' as IconBoxColor,
    icon: 'feather:activity',
  },
  {
    title: 'Customization',
    text: 'You can easily customize and fine-tune 8D-1 for your unique needs.',
    color: 'green' as IconBoxColor,
    icon: 'feather:settings',
  },
  {
    title: 'CSV Export',
    text: 'Export all data gathered by 8D-1 as a CSV to work with it further.',
    color: 'purple' as IconBoxColor,
    icon: 'feather:download',
  },
  {
    title: 'Email Notifications',
    text: 'Join the chat when needed or reactivate it with a follow-up question.',
    color: 'primary' as IconBoxColor,
    icon: 'feather:mail',
  },
  {
    title: 'File Search',
    text: '8D-1 accepts files during an interview and extracts required information.',
    color: 'green' as IconBoxColor,
    icon: 'feather:paperclip',
  },
]

const tasks = [
  {
    title: 'Handle LinkedIn inquiries',
    text: 'Never miss a potential partnership by ignoring LinkedIn messages. Let 8D-1 handle them for you.',
    color: 'green' as IconBoxColor,
    icon: 'feather:linkedin',
  },
  {
    title: 'Validate startup applications',
    text: 'Discover your next unicorn in the forest of pitch decks and application forms.',
    color: 'info' as IconBoxColor,
    icon: 'feather:mic',
  },
  {
    title: 'Pre-screen job candidates',
    text: 'Conduct initial screenings, ask relevant questions, and shortlist candidates for further interviews.',
    color: 'orange' as IconBoxColor,
    icon: 'feather:user-check',
  },
  {
    title: 'Do CustDev interviews',
    text: 'Understand your customers\' needs and preferences through in-depth, unscripted conversations.',
    color: 'red' as IconBoxColor,
    icon: 'feather:search',
  },
  {
    title: 'Collect feedback',
    text: 'Gather feedback from your clients to improve your products and services.',
    color: 'primary' as IconBoxColor,
    icon: 'feather:edit-3',
  },
  {
    title: 'And so much more...',
    text: 'You can fine-tune your assistant to fit your business needs perfectly.',
    color: 'default' as IconBoxColor,
    icon: 'feather:more-horizontal',
  }
];
</script>

<template>
  <div>
    <HeroN />

    <Section>
      <Container>
        <SectionTitle
          title="Level up your team"
          subtitle=""
          class="mb-10"
        />
        <ValuesSection :values="values" />
      </Container>
    </Section> 

    <Section>
      <Container>
        <SideSection
          subtitle=""
          title="Let it talk for you"
          content="Let AI handle the first contact. It asks follow-up questions, so you get comprehensive answers and can jump into the conversation when needed."
          image="/assets/illustrations/assistants.png"
          dark-image="/assets/illustrations/assistants_dark.png"
          image-width="800"
          image-height="625"
          to="app/create"
          cta="Skip hours of chatting"
        />
        <FeatureBlockH
          :features="tasks"
          :columns="4"
          :limit="8"
        />
      </Container>
    </Section>


    <Section>
      <Container>
        <SideSection
          reverse
          subtitle=""
          title="Better than forms"
          content="Get structured results while utilizing the power of unstructured interviews. You don't need to hire a team of interviewers to get the insights you need."
          image="/assets/illustrations/better-than-forms.png"
          dark-image="/assets/illustrations/better-than-forms.png"
          image-width="967"
          image-height="800"
          to="app/create"
          cta="Start a new research"
        />
      </Container>
    </Section>
    

    <Section>
      <Container>
        <CaseStudy class="mt-5">
          <SideSection
            subtitle="Case Study"
            title="Uncovering churn insights with Bitsgap"
            content="See why Bitsgap chose 8D-1's AI-powered interviews to gather comprehensive feedback"
            image="/assets/illustrations/bitsgap_case2.svg"
            dark-image="/assets/illustrations/bitsgap_case2_dark.svg"
            image-width="40"
            cta="Experience it yourself"
            to="/case-studies/bitsgap"
            inverted
          />
        </CaseStudy>
      </Container>
    </Section> 

    <Section>
      <Container>
        <HowItWorks />
      </Container>
    </Section> 

    <Section>
      <TestimonialBlockE
        image="/assets/illustrations/doktoroff.jpeg"
        name="Andrei Doktoroff"
        position="Serial Entrepreneur and VC"
        content="It's a fresh take on business communications."
        logo="/assets/illustrations/theintelligent.svg"
        curved
        reversed
      />
    </Section> 

    <Section>
      <Container>
        <SectionTitle
          title="Ideal employee"
          subtitle=""
          class="mb-10"
        />
        <PricingSingle />
      </Container>
    </Section>

    <Section>
      <Container>
        <SectionTitle
          title="More than an average ChatGPT assistant"
          subtitle=""
        />
        <FeatureBlockC
          :features="featuresAll"
          :limit="9"
          size="medium"
          animated
          horizontal
        />
      </Container>
    </Section>

    <HeroM />
  </div>
</template>